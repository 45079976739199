<template>
    <div class="delivery-page">
        <div class="container my-5 ">
            <homeSubscriptions />
        </div>
        <div class="container my-5 ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3">
                    Zamówienie zostanie dostarczone pod drzwi, jesli nie ma przeciwskazań (np. wolno biegający po posesji pies, zamknięta na klucz bramka).<br /><br />
                    Dostawy odbywają się codziennie od poniedziałku do soboty (za wyjatkiem dni wolnych od pracy). <br /><br /> 
                    Godziny dostaw zależą od ilości złożonych zamówień, lecz dokładamy wszelkich starań by ostatnia dostawa w danym dniu miała miejsce nie później niż o godzinie 7:30. <br /><br /> 
                    Każde zamówienie zapakowane jest indywidualnie i dostarczane przez kierowców posiadających książeczki do celów sanitarnych.<br /><br /> 
                    Korzystamy z opakowań papierowych, dlatego ważne jest by klient zapewnił miejsce w którym zamówienie może zostać bezpiecznie pozostawione.<br /><br /> 
                    Koszt dostawy uzalezniony jest od wielu czynników i podany jest w momencie składania zamówienia, na stronie z podsumowaniem. <br /><br /> 
                    Aby otrzymać zamówienie na drugi dzień, płatnosć za zamówienie musi byc zaksięgowana przed godziną 19:00.<br /><br /> 
                    W przypadku braku potwierdzenia wpłaty przed godziną 19:00, pierwsza dostawa zostanie zrealizowana w kolejnym dniu roboczym.<br /><br /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import homeSubscriptions from "@/components/home/Subscriptions.vue";

export default {
    name: "Delivery",
    components: {
        homeSubscriptions,
    },
    
    data: () => ({
        
    }),
};
</script>